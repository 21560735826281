import React, { useCallback, useEffect, useState } from "react";
import ProcessLayout from "../Common/ProcessLayout";
import { API_URL } from "../../imports";
import axios from "axios";
import GetIdeasFilewiseStatusLogs from "./GetIdeasComponents/GetIdeasFilewiseStatusLogs";
import FilesToProcess from "../Common/FilesToProcess";
import Process from "../Common/Process";
import CurrentProcessLogs from "../Common/CurrentProcessLogs";
import Popup from "../Common/Popup";
import { handleApiError } from "../Common/APIUtils";
import { formattedStartDateTime } from "../Common/CommonUtils";

function GetIdeas() {
    const [startProcess, setStartProcess] = useState(false);
    const [backgroundProcess, setBackgroundProcess] = useState(false);
    const [filesData, setFilesData] = useState([]);
    const [fetchedFilesData, setFetchedFilesData] = useState([]);
    const [logsData, setLogsData] = useState([]);
    const [processedFiles, setProcessedFiles] = useState([]);
    const [unprocessedFiles, setUnProcessedFiles] = useState([]);
    const [serverStatus, setServerStatus] = useState(false);
    const [serviceUrl, setServiceUrl] = useState("");
    const [serverLoadState, setServerLoadState] = useState(true);
    const [Loader, setLoader] = useState(true);
    const [processOption, setProcessOption] = useState("normal");
    const [serverOFF, setServerOFF] = useState(false);

    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    const openPopup = useCallback(() => {
        setIsOpenPopup(true);
    }, [setIsOpenPopup]);

    const handleStartProcess = async (data) => {
        if (processOption === "normal") {
            setStartProcess(true);

            const initialFiles = data.map((file) => {
                return { ...file, status: null, creation_date: "" };
            });

            setFilesData(initialFiles);
            setLogsData([]);

            const currentLogs = [];
            const updatedProcessedFiles = [];
            const updatedUnprocessedFiles = [];

            for (let i = 0; i < data.length; i++) {
                let startdt = formattedStartDateTime();
                let file = data[i];
                file.status = "Inprogress";
                file.start_date_time = startdt;
                file.creation_date = "";
                setFilesData((prevData) => {
                    const newData = [...prevData];
                    newData[i] = file;
                    return newData;
                });
                try {
                    const response = await axios.post(
                        `${API_URL}/GetIdeas/process/${sessionStorage.getItem(
                            "projectCode"
                        )}`,
                        {
                            id: file.id,
                            filename: file.filename,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    "access_token"
                                )}`,
                            },
                        }
                    );
                    if (response.data.status === "success") {
                        // Update the status of the current file to "success"
                        file.status = "success";
                        file.response = response.data.response;
                        const date = new Date();
                        file.creation_date = date;
                        currentLogs.push({
                            filename: file.filename,
                            creation_date: date,
                            ...response.data,
                        });
                        updatedProcessedFiles.push(file); // Add to processedFiles
                    } else {
                        // Update the status of the current file to "error"
                        file.status = "error";
                        file.response = response.data.response;
                        const errordate = new Date();
                        file.creation_date = errordate;
                        currentLogs.push({
                            filename: file.filename,
                            creation_date: errordate,
                            ...response.data,
                        });
                        updatedUnprocessedFiles.push(file); // Add to unprocessedFiles
                    }
                } catch (error) {
                    // Update the status of the current file to "error"
                    file.status = "error";
                    const errordate = new Date();
                    file.creation_date = errordate;
                    const errorMessage = handleApiError(error);
                    if (errorMessage !== "CoreAPIError") {
                        currentLogs.push({
                            filename: file.filename,
                            creation_date: errordate,
                        });
                        setMsg(errorMessage);
                        setIsSuccess(false);
                        openPopup();
                    } else {
                        file.response = error.response.data.response;
                        currentLogs.push({
                            filename: file.filename,
                            creation_date: errordate,
                            ...error.response.data,
                        });
                    }
                    updatedUnprocessedFiles.push(file); // Add to unprocessedFiles
                }

                setFilesData((prevData) => {
                    const newData = [...prevData];
                    newData[i] = file;
                    return newData;
                });
            }

            // Update the state with the updatedFiles array
            setLogsData(currentLogs);
            setProcessedFiles(updatedProcessedFiles); // Update processedFiles state
            setUnProcessedFiles(updatedUnprocessedFiles); // Update unprocessedFiles state
            setStartProcess(false);
        } else {
            // code for background process
            setBackgroundProcess(true);
            const fileIds = data.map((file) => file.id);
            try {
                const response = await axios.post(
                    `${API_URL}/GetIdeas/background/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        files: fileIds,
                        serverState: serverOFF
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                if (response) {
                    setMsg(response.data.message);
                    setServerStatus(response.data.serverStatus);
                    setIsSuccess(true);
                    openPopup();
                }
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            }
            setBackgroundProcess(false);
            ReloadData();
        }
    };

    useEffect(() => {
        const fetchTranscribedData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/GetIdeas/closedtranscriptfiles/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setFetchedFilesData(response.data.data);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        };
        const fetchStatus = async () => {
            try {
                const response = await axios.get(`${API_URL}/instancemonitor`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                    params: {
                        server_instance: "IDA",
                    },
                });
                if (response) {
                    setServerStatus(response.data.data[0].serverStatus);
                    setServiceUrl(response.data.data[0].service_url);
                }
            } catch (error) {
                const errorMessage = handleApiError(error);
                if (errorMessage) {
                    setServerStatus(false);
                    setServiceUrl("");
                }
            } finally {
                setServerLoadState(false);
            }
        };
        fetchTranscribedData();
        fetchStatus();
    }, [setFetchedFilesData, setServerStatus, openPopup]);

    const ReloadData = useCallback(() => {
        setLoader(true);
        const reloadTranscribedData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/GetIdeas/closedtranscriptfiles/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setFetchedFilesData(response.data.data);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        };
        reloadTranscribedData();
    }, [setFetchedFilesData, openPopup]);

    const tabs = [
        {
            id: 1,
            title: "Transcripted Files",
            content: (
                <FilesToProcess
                    serverStatus={serverStatus}
                    ReloadData={ReloadData}
                    items={fetchedFilesData}
                    startProcess={startProcess}
                    backgroundProcess={backgroundProcess}
                    setFilesData={handleStartProcess}
                    Loader={Loader}
                    setProcessOption={setProcessOption}
                    setServerOFF={setServerOFF}
                />
            ),
        },
        {
            id: 2,
            title: "Get Ideas Process",
            content: (
                <Process
                    startProcess={startProcess}
                    selectedFilesToProcess={filesData}
                />
            ),
        },
        {
            id: 3,
            title: "Logs",
            content: (
                <CurrentProcessLogs
                    logs={logsData}
                    processedfiles={processedFiles}
                    unprocessedfiles={unprocessedFiles}
                />
            ),
        },
        {
            id: 4,
            title: "All Files Status",
            content: <GetIdeasFilewiseStatusLogs />,
        },
    ];

    return (
        <>
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <ProcessLayout
                startProcess={startProcess}
                tabs={tabs}
                server="Idea Service"
                serverStatus={serverStatus}
                serviceUrl={serviceUrl}
                serverLoadState={serverLoadState}
                batching={false}
            />
        </>
    );
}

export default GetIdeas;
